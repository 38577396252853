import logo from './logo.png'
import safety1 from './safety/safety2.png'
import safety2 from './safety/safety2.png'
import tablet from './tablet.jpg'
import instaIcon from './instagram.png'

export const assets = {
  logo,
  safety1,
  safety2,
  tablet,
  instaIcon
} 